<template>
  <div>
    <form-section
      :title="$t('courierSet.additionalServices')"
      :tooltip="{icon:'mdi-help-circle-outline'}"
    >
      <template v-slot:message>
        <AdditionalServicesTooltipMessage />
      </template>
      <div
        v-for="serv in courierAddtionalServices"
        :key="serv.id"
        class="ma-3"
      >
        <service-field
          :id="serv.id"
          :label="serv.name"
          :messages="serv.description"
          :remark="serv.remark"
          :code="serv.serviceId"
          :selected-service="isSelected(serv)"
          :active-service="isActive(serv)"
          :visible-service="isVisible(serv)"
          :parameters="serv.parameters"
          @@activate="activate"
          @@deactivate="deactivate(serv)"
          @@update="updateService"
        />
      </div>
    </form-section>
  </div>
</template>

<script>
import { glsAdditionalServices as courierAddtionalServices } from '@/constants/Speditors/GLS/AddtionalServices';
import { mapGetters, mapActions } from 'vuex';
import additionalServicesMixin from '@/mixins/additionalServicesMixin';
import AdditionalServicesTooltipMessage from '@/components/tooltips/contents/AdditionalServicesTooltipMessage.vue';

export default {
  name: 'AdditionalServices',
  components: {
    AdditionalServicesTooltipMessage,
  },
  mixins: [additionalServicesMixin],
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    additionalServices: [],
    courierAddtionalServices,
    unchangedSet: null,
    id: null,
    tenantId: null,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getOrganization']),
  },
  methods: {
    ...mapActions('spedition', ['setAdditionalServices']),
    update() {
      const state = this.additionalServices === this.unchangedSet ? 1 : 3;
      const serviceParameters = {
        clientEntityState: this.isNew || !this.id ? 4 : state,
        id: this.id,
        tenantId: this.tenantId,
        services: this.additionalServices,
      };
      this.setAdditionalServices({ additionalServices: serviceParameters });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
