<template>
  <div>
    <form-section :title="$t('courierSet.prcelContent')">
      <v-row>
        <v-col>
          <text-field
            v-model="getGlsDefaultValues.referenceNumber"
            :title=" $t('courierSet.referenceNumber')"
            rules="max:100"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getGlsDefaultValues.comment"
            :title=" $t('courierSet.remarks')"
            rules="max:100"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getGlsDefaultValues() {
      return this.getConfigurationSet().glsDefaultValues;
    },
  },
};
</script>

<style scoped>
</style>
